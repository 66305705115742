/*
 * @Author: gaofengxiang gaofengxiang@gzsle.com
 * @Date: 2023-09-20 09:36:14
 * @LastEditors: gaofengxiang gaofengxiang@gzsle.com
 * @LastEditTime: 2023-09-20 11:28:17
 * @FilePath: \official-site\src\components\HomeBanner\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';

import Banner from '../Banner';
import banner1 from '../../static/banner/home-head/轮播首图1.jpg';
// import banner2 from '../../static/banner/home-head/轮播首图2.jpg';
import banner3 from '../../static/banner/home-head/轮播首图3.jpg';
import banner4 from '../../static/banner/home-head/轮播首图4.jpg';
import banner5 from '../../static/banner/home-head/轮播首图5.jpg';
import banner6 from '../../static/banner/home-head/轮播首图6.jpg';
import './index.sass';

// const urls = [banner1, banner2, banner3, banner4];
const urls = [banner5, banner3, banner1,  banner4,  banner6];

export default function HomeBanner({ ...props }) {
  return <Banner urls={urls} />;
}
