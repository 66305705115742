import React from 'react';
import PlatformLogos from '../PlatformLogos';
import copyright from '../../static/Copyright-sm.png';
import qrcode from '../../static/qrcode.png';
import follow from '../../static/follow.jpg';

import './index.sass';

export default function Follow({ ...props }) {

  return <div className='follow_box'>
    <img src={follow} />
  </div>
  // return (
  //   <div className='follow-holder'>
  //     {/* <div className='tips'>{'扫码关注果子熟了，\n第一时间获取果子熟了相关资讯。'}</div> */}
  //     {/* <div>
  //       <img src={copyright} alt='copyright' className='copyright-img' />
  //     </div> */}
  //     {/* <div>
  //       <img src={qrcode} alt='qrcode' className='qrcode-img' />
  //     </div> */}
  //     <div className='platform-line'>
  //       <div className='title-box'>
  //         <div className='title-tip'>RIPE FRUIT</div>
  //         <div className='title'>{'NATURAL FOOD \nMADE IN CHINA'}</div>
  //       </div>
  //       <PlatformLogos className='platform-logos'></PlatformLogos>
  //     </div>
  //     <div className='bottom-tip'>
  //       {'When the fruit is ripe,\nGet the information about new products and ripe fruit as soon as possible.'}
  //     </div>
  //   </div>
  // );
}
