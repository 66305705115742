import React from 'react';

import headLogo from '../../static/head-logo.jpg';
import './index.sass';

export default function Header({ ...props }) {

  return (
    <div className='page-header'>
      <img src={headLogo} alt='head-logo' className='main-logo header-logo' />
      <div className='header-tip'>Copyright © {(new Date()).getFullYear()} Ripe Fruit (Nanjing) Food Inc. All rights reserved.</div>
      <div className='header-menu-icon iconfont iconmenu'></div>
    </div>
  );
}
