import React from 'react';

import borderLeft from '../../static/border-tip-left.png';
import borderRight from '../../static/border-tip-right.png';
import './index.sass';

export default function HomeDeclar({ ...props }) {
  return (
    <div className='home-declaration-holder'>
      <div className='left-logo'>{'年轻饮料\n果子熟了'}</div>
      <div className='info-box'>
        <div className='border-tip text-right'>
          <img src={borderRight} alt='' className='border-tip-right' />
        </div>
        <div className='title-tip'>RIPE FRUIT</div>
        <div className='title'>{'NATURAL FOOD \nMADE IN CHINA'}</div>
        <div className='motto'>生而有力，做年轻人喜欢的饮料</div>
        <div className='content'>
          {
            '果子熟了，希望用一瓶饮料，让你感受到年轻该有的快乐。\n不制造焦虑，只贩卖快乐，让饮料成为情绪的调节剂，是果子熟了成立的初衷。\n从年轻饮料出发，做潮流生活风向标，成为深受年轻人喜爱的国潮食品厂牌。'
          }
        </div>
        <div className='border-tip'>
          <img src={borderLeft} alt='' className='border-tip-left' />
        </div>
      </div>
    </div>
  );
}
