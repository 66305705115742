import React from 'react';

import './index.sass';

export default function RouterLine({ hash = '', routes = [] }) {
  return (
    <div className='router-line'>
      <div className='router-sign'></div>
      {routes.map(({ title, value }) => (
        <a className={`router-item ${hash === value ? 'chosed' : ''}`} href={value} key={value}>
          {title}
        </a>
      ))}
    </div>
  );
}
