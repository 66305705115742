import React from 'react';

import './index.sass';

export default function NewsPic({ url = '', tips = '', img = '', title = '', date = '', text = '' }) {
  return (
    <div className='news-pic'>
      <div
        className='trangle-placeholder'
      >
        <div>
          <div className='image' style={{
            backgroundImage: `url(${img})`,
          }}></div>
          <div className='tip' style={{
            backgroundImage: `url(${tips})`,
          }} onClick={() => {
            if (url) window.open(url);
          }}></div>
          {/* <div className='title'>{title}</div>
          <div className='date'>{date}</div>
          <div className='text'>{text}</div>
          <div className='button' onClick={() => {
            if (url) window.open(url);
          }}>了解详情</div> */}
        </div>
      </div>
    </div>
  );
}
