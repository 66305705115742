import React from 'react';

import declaration from '../../static/declaration.png';
import borderLeft from '../../static/border-tip-left.png';
import borderRight from '../../static/border-tip-right.png';
import declarationImg from '../../static/declaration-img.png';
import './index.sass';

export default function Declaration({ showImg }) {
  return (
    <div className='line-mb'>
      <div className='declaration-holder'>
        <img src={declaration} alt='declaration' className='left-logo' />
        <div className='info-box'>
          <div className='border-tip text-right'>
            <img src={borderRight} alt='' className='border-tip-right' />
          </div>
          <div className='content'>
            {
              '目前我们旗下仅有「果子熟了」和「东制方造」两个品牌\n除此之外所有与此类似或者添加前后缀的品牌均与我们无关。\n我司拥有「果子熟了」和「东制方造」的完整商标权，\n对于任何企业或个人侵犯果子熟了注册商标专用权的行为，我们都将予以打击，并追究相关主体的法律责任。'
            }
          </div>
          <div className='border-tip'>
            <img src={borderLeft} alt='' className='border-tip-left' />
          </div>
        </div>
      </div>
      {showImg && <img src={declarationImg} alt='home-img' className='declaration-img' />}
    </div>
  );
}
