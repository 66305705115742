/*
 * @Author: gaofengxiang gaofengxiang@gzsle.com
 * @Date: 2023-09-20 09:36:14
 * @LastEditors: gaofengxiang gaofengxiang@gzsle.com
 * @LastEditTime: 2023-09-20 11:30:10
 * @FilePath: \official-site\src\components\InformationBanner\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';

import Banner from '../Banner';

import banner1 from '../../static/banner/information/1-1820x480果汁茶.jpg';
import banner3 from '../../static/banner/information/3-1820x480多多.png';
import banner5 from '../../static/banner/information/5-1820x480无糖茶.jpg';
import banner6 from '../../static/banner/information/6-1820x480冷链果汁.jpg';
import './index.sass';

const urls = [banner5, banner3, banner1, banner6];

export default function InformationBanner({ ...props }) {
  return <Banner urls={urls} options={{ arrows: true }} />;
}
