import React from 'react';

import guozhicha from '../../static/products/guozhicha.jpg';
import guozhichaTip from '../../static/products/guozhichaTip.jpg';
import lenglian from '../../static/products/lenglian.jpg';
import lenglianTip from '../../static/products/lenglianTip.jpg';
import wutangcha from '../../static/products/wutangcha.jpg';
import wutangchaTip from '../../static/products/wutangchaTip.jpg';
import chahanhan from '../../static/products/chahanhan.jpg';
import chahanhanTip from '../../static/products/chahanhanTip.jpg';
import duoduocha from '../../static/products/duoduocha.jpg';
import duoduochaTip from '../../static/products/duoduochaTip.jpg';
import huolishuipinheng from '../../static/products/huolishuipinheng.jpg';
import huolishuipinhengTip from '../../static/products/huolishuipinhengTip.jpg';
import dun from '../../static/products/dun.jpg';
import dunTip from '../../static/products/dunTip.jpg';
import xueli from '../../static/products/xueli.jpg'
import xueliTip from '../../static/products/xueliTip.jpg'
import shunai from '../../static/products/shunai.jpg'
import shunaiTip from '../../static/products/shunaiTip.jpg'
import Product from '../Product';
import './index.sass';

const productList = [
  {
    img: wutangcha,
    tipImg: wutangchaTip,
    type: '',
    name: '',
    nameSign: '',
    tips: ['', '', ''],
    sign: 'Dietary fiber 1 bottle ≈5 lemons\nRestore the fresh and sour taste of hand-played \nlemons in stores 0 fat low burden',
  },
  {
    img: duoduocha,
    tipImg: duoduochaTip,
    type: '多多柠檬茶系列',
    name: '多多柠檬茶',
    nameSign: 'MANYMANYLEMON TEA',
    tips: ['1瓶≈5个柠檬的膳食纤维', '还原专门店手打柠檬的新鲜酸爽', '0脂肪，低负担'],
    sign: 'Dietary fiber 1 bottle ≈5 lemons\nRestore the fresh and sour taste of hand-played \nlemons in stores 0 fat low burden',
  },
  {
    img: guozhicha,
    tipImg: guozhichaTip,
    type: '多多柠檬茶系列',
    name: '多多柠檬茶',
    nameSign: 'MANYMANYLEMON TEA',
    tips: ['1瓶≈5个柠檬的膳食纤维', '还原专门店手打柠檬的新鲜酸爽', '0脂肪，低负担'],
    sign: 'Dietary fiber 1 bottle ≈5 lemons\nRestore the fresh and sour taste of hand-played \nlemons in stores 0 fat low burden',
  },
  {
    img: huolishuipinheng,
    tipImg: huolishuipinhengTip,
    type: '活力水平衡系列',
    name: '活力水平衡\n维生素饮料',
    nameSign: '',
    tips: ['低糖 0脂肪', '健康无负担', '维生素C·维生素B6·烟酰胺·锌'],
    sign: 'Low sugar 0 fat\nHealthy every day\nIt contains vitamin C, vitamin B6, nicotinamide and zinc',
  },
  {
    img: lenglian,
    tipImg: lenglianTip,
    type: '',
    name: '',
    nameSign: '',
    tips: ['', '', ''],
    sign: 'Dietary fiber 1 bottle ≈5 lemons\nRestore the fresh and sour taste of hand-played \nlemons in stores 0 fat low burden',
  },
  {
    img: shunai,
    tipImg: shunaiTip,
    type: '熟奶茶系列',
    name: '熟奶茶',
    nameSign: '',
    tips: ['低糖·0反式脂肪酸', 'MCT脂肪粉添加量≥2‰', '137°C高温熟化，低糖也香'],
    sign: 'Low sugar 0 trans fatty acids\nMCT fat powder added amount ≥2‰\nHigh temperature curing at 137℃ low sugar is sweet',
  },
  {
    img: chahanhan,
    tipImg: chahanhanTip,
    type: '茶憨憨系列',
    name: '茶憨憨',
    nameSign: 'Hanhan ice tea',
    tips: ['低糖·0脂肪', '10%真实果汁，双倍果味', '甄选东方清爽好茶，自然鲜爽'],
    sign: 'Low sugar 0 fat\n10% real juice double fruit\nSelection of Oriental refreshing tea natural fresh',
  },
  {
    img: dun,
    tipImg: dunTip,
    type: '炖系列',
    name: '够真红枣炖山楂\n够真炖雪梨/炖桃',
    nameSign: '',
    tips: ['优选当季鲜果', '果汁含量≥30%', '模拟手工炖煮，还原记忆中的老味道'],
    sign: 'Preferably fresh fruit in season\nJuice content≥ 30%\nSimulating manual stewing to restore the old taste in memory',
  },
  {
    img: xueli,
    tipImg: xueliTip,
    type: '雪梨系列',
    name: '桃气弹 / 桔气弹',
    nameSign: 'Peach gas bomb / Orange gas bomb',
    tips: ['0糖 0脂 0卡', '三倍超高含气量', '6种人气水果味'],
    sign: '0 sugar 0 fat 0 calories\n3 times ultra-high gas content\nSix popular fruit flavors',
  },
];

export default function ProductList({ ...props }) {
  return (
    <div className='holder'>
      {productList.map((product, i) => (
        <Product {...product} alignRight={!(i % 2)} key={i} />
      ))}
    </div>
  );
}
