import React from 'react';
import weiboQRCode from '../../static/footer/1-微博二维码.jpg'
import weixinQRCode from '../../static/footer/2-微信二维码.jpg'
import douyinQRCode from '../../static/footer/3-抖音二维码.jpg'
import xiaohongshuQRCode from '../../static/footer/4-小红书二维码.jpg'
import Velocity from 'velocity-animate'
import './index.sass';

const phone = '400-9957-521';

const icons = [
  {
    icon: 'icon-xinlangweibo',
    code: weiboQRCode
  },
  {
    icon: 'icon-weixin',
    code: weixinQRCode
  },
  {
    icon: 'icon-douyin',
    code: douyinQRCode
  },
  {
    icon: 'icon-xiaohongshu',
    code: xiaohongshuQRCode
  }
]

const tips = [
  {
    title: '关于我们',
    subTitle: '公司介绍',
    value: '#home',
  },
  {
    title: '果子熟了',
    subTitle: '产品介绍',
    value: '#product',
  },
  {
    title: '新闻公告',
    subTitle: '公司新闻',
    value: '#information',
  },
  {
    title: '联系我们',
    phone: '400-9957-521'
  },
  {
    title: '加入我们',
    subTitle: '人才招聘',
  },
]

const IconBox = ({ icon = "#icon-xinlangweibo", code }) => {

  return <div>
    <span className={`footer-icon button iconfont ${icon}`}>
      <span className='QRcode' style={{ backgroundImage: `url(${code})` }}></span>
    </span>
  </div>
}

export default function PageFooter({ ...props }) {

  const setScrollTop = (top) => {
    let body = null;
    if (document.documentElement && document.documentElement.scrollTop) {
      body = document.documentElement;
    } else if (document.body) {
      body = document.body;
    }
    Velocity(body, 'scroll', { duration: 500, easing: "easeOutQuart", offset: top });
  }

  return <div className='page-footer-holder'>
    <div className='content'>
      <div className='content-left'>
        {
          tips.map(item => <div key={item.title}>
            <span>{item.title}</span>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              item.phone ? <span className='phone'>{item.phone}</span> : <a className='button' onClick={() => {
                if (item.title === "加入我们") {
                  window.open("https://gzsle.jobs.feishu.cn/index", "_blank")
                } else {
                  document.documentElement.scrollTop = 0;
                  window.location.href = item.value;
                }

              }} >{item.subTitle}</a>
            }
          </div>)
        }
      </div>
      <div className='content-right'>
        {
          icons.map(item => <IconBox key={item.icon} {...item} />)
        }
      </div>
    </div>
    <div className='bottom'>
      <a target="_blank" href="https://xyt.xcc.cn/getpcInfo?sn=1449912243011829760&certType=8&url=*.gzsle.com&language=CN" rel="noreferrer"><img src="https://program.xinchacha.com/xyt/xcc_small_ssl.png" width={16} height={16} style={{ marginTop: 2 }} /></a>
      <a href='https://beian.miit.gov.cn' className='record' target='_blank' rel="noreferrer">
        版权所有：菓子熟了（南京）食品有限公司
      </a>
      <a href='https://beian.miit.gov.cn' className='record' target='_blank' rel="noreferrer">
        苏ICP备2021008077号-1
      </a>
    </div>
  </div>
}
