import React from 'react';

import NewsPic from '../NewsPic';
import './index.sass';

const COLUMNS = 2;

const TitleNews = ({ title, url }) => (
  <div
    className='title-news'
    onClick={() => {
      if (url) window.open(url);
    }}
  >
    {title}
  </div>
);

export default function NewsBox({ ...props }) {
  const { imgNews = [], titleNews = [] } = window?._config?.news || {};
  return (
    <div
      className='news-holder'
      onClick={(e) => {
        console.log(e);
      }}
    >
      {[...Array(Math.ceil(imgNews.length / COLUMNS))].map((v, i) => (
        <div className='split-line' key={i}>
          {imgNews[i * COLUMNS] && <NewsPic {...imgNews[i * COLUMNS]} />}
          {imgNews[i * COLUMNS + 1] ? <NewsPic {...imgNews[i * COLUMNS + 1]} /> : <div />}
        </div>
      ))}
      <div className='title-news-holder'>
        {[...Array(Math.ceil(titleNews.length / COLUMNS))].map((v, i) => (
          <div className='split-line' key={i}>
            {titleNews[i * COLUMNS] && <TitleNews {...titleNews[i * COLUMNS]} />}
            {titleNews[i * COLUMNS + 1] ? <TitleNews {...titleNews[i * COLUMNS + 1]} /> : <div />}
          </div>
        ))}
      </div>
    </div>
  );
}
