import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.sass';

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className='custom-prev'
      onClick={onClick}
    >
      <span className='iconfont icon-prev'></span>
    </div>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className='custom-next'
      onClick={onClick}
    >
      <span className='iconfont icon-next'></span>
    </div>
  );
}

const settings = {
  dots: false,
  autoplay: true,
  lazyLoad: true,
  infinite: true,
  autoplaySpeed: 5000,
  speed: 500,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

export default function Banner({ urls = [], options = {} }) {

  return (
    <div className='banner-holder'>
      <Slider {...settings} {...options}>
        {urls.map((url) => (
          <div key={url}>
            <img src={url} alt='' />
          </div>
        ))}
      </Slider>
    </div>
  );
}
