import React, { useState, useEffect } from 'react';

import Header from './components/Header';
import RouterLine from './components/RouterLine';
import ProductBanner from './components/ProductBanner';
import ProductList from './components/ProductList';
import Declaration from './components/Declaration';
import Follow from './components/Follow';
import PageFooter from './components/PageFooter';
import HomeBanner from './components/HomeBanner';
import InformationBanner from './components/InformationBanner';
import HomeDeclar from './components/HomeDeclar';
import HomeImgList from './components/HomeImgList';
import WordMark from './components/WordMark';
import Map from './components/Map';
import NewsBox from './components/NewsBox';
import './App.css';

// import mainLogo from './static/head-logo.jpg';
import headPic from './static/head-pic.png';

const routes = [
  {
    title: 'Home/首页',
    value: '#home',
  },
  {
    title: 'Product/产品',
    value: '#product',
  },
  {
    title: 'Information/资讯',
    value: '#information',
  },
  {
    title: 'Us/我们',
    value: '#us',
  },
];

const hashArr = routes.map(({ value }) => value);

const routerPages = {
  '#home': (
    <div id='home'>
      <HomeBanner />
      <HomeDeclar />
      <HomeImgList />
      {/* <div className='text-right line-margin'>
        <img src={mainLogo} alt='main-logo' className='main-logo' />
      </div> */}
    </div>
  ),
  '#product': (
    <div id='product'>
      <img src={headPic} alt='head-pic' className='head-pic' />
      <ProductBanner />
      <ProductList />
    </div>
  ),
  '#information': (
    <div id='information'>
      <InformationBanner />
      <NewsBox />
    </div>
  ),
  '#us': (
    <div id='us'>
      <ProductBanner />
      {/* <Map /> */}
      <WordMark />
    </div>
  ),
};

export default function App({ ...props }) {
  const [hash, setHash] = useState(window?.location?.hash || hashArr[0]);

  if (!hashArr.includes(hash)) setHash(hashArr[0]);
  useEffect(() => {
    function refreshHash(event) {
      const hash = window?.location?.hash || '';
      if (hashArr.includes(hash)) setHash(hash);
    }
    window.addEventListener('hashchange', refreshHash);
    return () => {
      window.removeEventListener('hashchange', refreshHash);
    };
  }, []);
  return (
    <div className='App'>
      <div className='content-holder'>
        <Header />
        <RouterLine hash={hash} routes={routes} />
        {routerPages[hash]}
        <Declaration showImg={hash === '#us'} />
        <Follow />
        <PageFooter />
      </div>
    </div>
  );
}
