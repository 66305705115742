/*
 * @Author: gaofengxiang gaofengxiang@gzsle.com
 * @Date: 2023-09-20 09:36:14
 * @LastEditors: gaofengxiang gaofengxiang@gzsle.com
 * @LastEditTime: 2023-10-08 09:33:36
 * @FilePath: \official-site\src\components\HomeImgList\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';

import image from '../../static/homeImgs/龙井无糖乌龙.jpg';
import image1 from '../../static/homeImgs/1-首页_03.jpg';
import image3 from '../../static/homeImgs/1-首页_08.jpg';
import image4 from '../../static/homeImgs/1-首页_10.jpg';
import imagEcold from '../../static/homeImgs/冷链果汁.jpg';
import image10 from '../../static/homeImgs/1-首页_11.jpg';
import image5 from '../../static/homeImgs/1-首页_12.jpg';
import image6 from '../../static/homeImgs/1-首页_14.jpg';
import image8 from '../../static/homeImgs/1-首页_18.jpg';
import image9 from '../../static/homeImgs/雪梨.jpg';

import './index.sass';

const list = [image, image3, image1 , image4, imagEcold, image10, image5, image6, image8, image9];

export default function HomeImgList({ ...props }) {
  return (
    <div className='home-img-list-holder'>
      {list.map((url) => (
        <img src={url} key={url} alt='home-img' />
      ))}
    </div>
  );
}
