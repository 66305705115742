import React from 'react';
import './index.sass';

export default function Product({
  alignRight = true,
  img = '',
  tipImg = '',
  type = '',
  name = '',
  nameSign = '',
  tips = [],
  sign = '',
}) {
  const poster =  img ? (
    <img src={alignRight ? img: tipImg} className='product-poster' alt='product-poster' />
  ) : (
    <div className='product-poster'></div>
  );

  const tipBox = tipImg ? (
    <img src={!alignRight ? img: tipImg} className='info-box' alt='info-box' />
  ) : (
    <div className='info-box'></div>
  );
  return (
    <div className='product-holder'>
      {poster}
      {tipBox}
    </div>
  );
}
